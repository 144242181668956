import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  USER_HAS_PASSWORD,
  TOKEN_USER_HAS_PASSWORD,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
} from './User';
import { COMPANY, COMPANIES } from './Company';
import { SETTINGS, SETTING_KEY, SETTING_KEY_FOR_ADMIN } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { EXPORT_OPERATIONS, OPERATIONS } from './Operation';
import { TAGS } from './Tag';
import { GET_SIGNATURE } from './Signature';
import { IQuery } from '../GraphqlService';
import { ObjectStore } from '../../../enums/EnumsValues';
import {
  GET_TENANT_PICTURE,
  TENANT,
  TENANTS,
  TENANTS_ASSOCIATED_WITH_USER,
  TENANT_COUNT,
} from './Tenant';
import { VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN } from './VeryTokens';
import { LOGIN_PROVIDERS } from './LoginProvider';
import { LOGIN_METHODS } from './LoginMethod';
import { SEND_EMAIL, SEND_CONTACT_EMAIL } from './Email';
import { CHECK_INVITATION, INVITATIONS } from './Invitation';
import {
  TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS_LIST,
  USER_HAS_ACCEPTED_TERMS_AND_CONDITIONS,
} from './TermsAndConditions';
import {
  TENANT_SETTING,
  TENANT_SETTINGS,
  TENANT_SETTINGS_COUNT,
} from './TenantSetting';
import { NOTES } from './Note';
import { PLANS } from './Plan';
import { USER_PLANS } from './UserPlan';

let Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  companies: {
    name: 'companies',
    gql: COMPANIES,
    objectStore: ObjectStore.Company,
  },
  company: { name: 'company', gql: COMPANY },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  operations: {
    name: 'operations',
    gql: OPERATIONS,
    objectStore: ObjectStore.Operation,
  },
  notes: { name: 'notes', gql: NOTES },
  plans: { name: 'plans', gql: print(PLANS) },
  userPlans: { name: 'userPlans', gql: USER_PLANS },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  userHasPassword: { name: 'userHasPassword', gql: USER_HAS_PASSWORD },
  tokenUserHasPassword: {
    name: 'tokenUserHasPassword',
    gql: TOKEN_USER_HAS_PASSWORD,
  },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  getSignature: { name: 'getSignature', gql: GET_SIGNATURE },
  tenants: { name: 'tenants', gql: TENANTS },
  tenant: { name: 'tenant', gql: TENANT },
  getTenantPicture: {
    name: 'getTenantPicture',
    gql: GET_TENANT_PICTURE,
  },
  tenantCount: { name: 'tenantCount', gql: TENANT_COUNT },
  exportOperations: { name: 'exportOperations', gql: EXPORT_OPERATIONS },
  verifyCompleteRegisterByEmailToken: {
    name: 'verifyCompleteRegisterByEmailToken',
    gql: VERIFY_COMPLETE_REGISTER_BY_EMAIL_TOKEN,
  },
  tags: { name: 'tags', gql: TAGS, objectStore: ObjectStore.Tag },
  loginProviders: { name: 'loginProviders', gql: LOGIN_PROVIDERS },
  loginMethods: { name: 'loginMethods', gql: LOGIN_METHODS },
  sendEmail: { name: 'sendEmail', gql: SEND_EMAIL },
  sendContactEmail: { name: 'sendContactEmail', gql: SEND_CONTACT_EMAIL },
  termsAndConditions: { name: 'termsAndConditions', gql: TERMS_AND_CONDITIONS },
  userHasAcceptedTermsAndConditions: {
    name: 'userHasAcceptedTermsAndConditions',
    gql: USER_HAS_ACCEPTED_TERMS_AND_CONDITIONS,
  },
  termsAndConditionsList: {
    name: 'termsAndConditionsList',
    gql: TERMS_AND_CONDITIONS_LIST,
  },
  checkInvitation: {
    name: 'checkInvitation',
    gql: CHECK_INVITATION,
  },
  invitations: {
    name: 'invitations',
    gql: INVITATIONS,
  },
  tenantsAssociatedWithUser: {
    name: 'tenantsAssociatedWithUser',
    gql: TENANTS_ASSOCIATED_WITH_USER,
  },
  tenantSettings: {
    name: 'tenantSettings',
    gql: TENANT_SETTINGS,
  },
  tenantSettingsCount: {
    name: 'tenantSettingsCount',
    gql: TENANT_SETTINGS_COUNT,
  },
  tenantSetting: {
    name: 'tenantSetting',
    gql: TENANT_SETTING,
  },
  getAppSettingByKeyForAdmin: {
    name: 'getAppSettingByKeyForAdmin',
    gql: SETTING_KEY_FOR_ADMIN,
  },
};

// Check type maintaining inferred type
const checkTypeQuery = <Obj extends Record<string, IQuery>>(obj: Obj) => obj;
Query = checkTypeQuery(Query);

export { Query };
