import {
  HomeOutlined,
  ProfileOutlined,
  SettingOutlined,
  ControlOutlined,
  ShopOutlined,
  LockOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  FileTextOutlined,
  MailOutlined,
  BlockOutlined,
} from '@ant-design/icons';
import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import { EnumsValues, PageKey } from '../enums/EnumsValues';
import { IRouteConfig } from '../interfaces/routeConfig';
import { Suspense, lazy } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import AdminInvitationPage from '../pages/AdminInvitation/AdminInvitationPage';
import InvitationPage from '../pages/Invitation/Invitation';
import { Redirect } from 'react-router';
import TenantSettingPage from '../pages/TenantSetting/TenantSettingPage';
import EmptyLayout from '../layouts/emptyLayout/index';

const CheckCompany = lazy(() => import('../pages/CheckCompany/CheckCompany'));
const HomePage = lazy(() => import('../pages/Home/HomePage'));
const LoginPage = lazy(() => import('../pages/Login/LoginPage'));
const PublicLayout = lazy(() => import('../layouts/publicLayout'));
const RegisteredLayout = lazy(() => import('../layouts/registered'));
const AppSettingPage = lazy(() => import('../pages/AppSetting/AppSettingPage'));
const ResetPasswordContentPage = lazy(
  () => import('../pages/ResetPassword/ResetPasswordContentPage'),
);
const SetPasswordContentPage = lazy(
  () => import('../pages/SetPassword/SetPasswordPage'),
);
const ProfilePage = lazy(() => import('../pages/Profile/ProfilePage'));
const CompanyPage = lazy(() => import('../pages/Company/CompanyPage'));
const OperationPage = lazy(() => import('../pages/Operation/OperationPage'));
const NotePage = lazy(() => import('../pages/Note/NotePage'));
const PlanPage = lazy(() => import('../pages/Plan/PlanPage'));
const RolePage = lazy(() => import('../pages/Role/RolePage'));
const PermissionPage = lazy(() => import('../pages/Permission/PermissionPage'));
const UserPage = lazy(() => import('../pages/User/UserPage'));
const AboutPage = lazy(() => import('../pages/About/AboutPage'));
const Reminder2FA = lazy(
  () => import('../pages/Totp2FA/Reminder2FA/Reminder2FA'),
);
const Generate2FA = lazy(
  () => import('../pages/Totp2FA/Generate2FA/Generate2FA'),
);
const Validate2FA = lazy(
  () => import('../pages/Totp2FA/Validate2FA/Validate2FA'),
);
const Config2FA = lazy(() => import('../pages/Totp2FA/Config2FA/Config2FA'));
const LoginMethodsPage = lazy(
  () => import('../pages/LoginMethods/LoginMethodsPage'),
);
const TenantPage = lazy(() => import('../pages/Tenant/TenantPage'));
const CompleteRegisterPage = lazy(
  () => import('../pages/CompleteRegister/CompleteRegisterPage'),
);
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));
const MaintenancePage = lazy(
  () => import('../pages/Maintenance/MaintenancePage'),
);
const StatusPage = lazy(() => import('../pages/Status/StatusPage'));
const ContactPage = lazy(() => import('../pages/Contact/ContactPage'));
const TermsAndConditionsPage = lazy(
  () => import('../pages/TermsAndConditions/TermsAndConditionsPage'),
);
const ManageTermsAndConditionsPage = lazy(
  () =>
    import('../pages/ManageTermsAndConditions/ManageTermsAndConditionsPage'),
);
const SelectTenantPage = lazy(
  () => import('../pages/SelectTenantPage/SelectTenant'),
);

const redirectIfNotExist = {
  path: '*',
  component: () => <Redirect to="/" />,
  key: PageKey.RedirectIfNotExist,
  hideInMenu: true,
};

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirectPage,
    key: PageKey.DefaultRedirect,
  },
  {
    path: '/app',
    component: (props: any) => (
      <Suspense fallback={<PageLoading />}>
        <RegisteredLayout {...props} />
      </Suspense>
    ),
    key: PageKey.App,
    routes: [
      {
        path: '/app/home',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <HomePage />
          </Suspense>
        ),
        name: 'Home',
        translation_key: 'pages.home',
        icon: <HomeOutlined />,
        key: PageKey.Home,
        requiresTenantSelected: true,
      },
      {
        path: '/app/company',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <CompanyPage />
          </Suspense>
        ),
        name: 'Empresas',
        translation_key: 'pages.companies',
        icon: <ShopOutlined />,
        key: PageKey.Company,
        requiresTenantSelected: true,
      },
      {
        path: '/app/operation',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <OperationPage />
          </Suspense>
        ),
        name: 'Operación',
        translation_key: 'pages.operation',
        icon: <ProfileOutlined />,
        key: PageKey.Operation,
        requiresTenantSelected: true,
      },
      {
        path: '/app/note',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <NotePage />
          </Suspense>
        ),
        name: 'Note',
        translation_key: 'pages.notes',
        icon: <BlockOutlined />,
        key: 'notes',
      },
      {
        path: '/app/plans',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <PlanPage />
          </Suspense>
        ),
        name: 'Planes',
        translation_key: 'pages.plans',
        icon: <LockOutlined />,
        key: 'plans',
      },
      {
        path: '/app/config2fa',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Config2FA />
          </Suspense>
        ),
        hideInMenu: true,
        name: 'Autenticación 2FA',
        translation_key: 'pages.auth2FA',
        icon: <LockOutlined />,
        key: PageKey.Config2fa,
        requiresTenantSelected: true,
      },
      {
        path: '/app/loginmethods',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <LoginMethodsPage />
          </Suspense>
        ),
        hideInMenu: true,
        name: 'Métodos de autenticación',
        icon: <LockOutlined />,
        key: PageKey.LoginMethods,
        requiresTenantSelected: true,
      },
      {
        path: '/app/administration',
        name: 'Administración',
        translation_key: 'pages.administration',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: PageKey.Administration,

        routes: [
          {
            path: '/app/administration/tenant-setting',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <TenantSettingPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.ReadTenantSetting,
            name: 'Configuración',
            translation_key: 'pages.tenantConfiguration',
            key: PageKey.TenantSetting,
            hideInMenu: (_data, IsAppMultitenant, isAdmin) => {
              return !(IsAppMultitenant || isAdmin);
            },
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/role',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <RolePage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
            translation_key: 'pages.roles',
            key: PageKey.Role,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/permission',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <PermissionPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            translation_key: 'pages.permissions',
            key: PageKey.Permission,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/tenant',
            component: () => (
              <Suspense fallback={<PageLoading />}>
                <TenantPage />
              </Suspense>
            ),
            authority: EnumsValues.Functions.TenantRead,
            name: 'Tenants',
            translation_key: 'pages.tenants',
            key: PageKey.Tenant,
            hideInMenu: (_data, IsAppMultitenant, isAdmin) => {
              return !(IsAppMultitenant || isAdmin);
            },
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/invitations',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <AdminInvitationPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserCreate,
            hideInMenu: true,
            name: 'Invitaciones',
            translation_key: 'pages.invitations',
            key: PageKey.Invitations,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/user',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <UserPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Usuarios',
            translation_key: 'pages.users',
            key: PageKey.User,
            requiresTenantSelected: true,
          },
          {
            path: '/app/administration/termsAndConditions',
            component: (props: any) => (
              <Suspense fallback={<PageLoading />}>
                <ManageTermsAndConditionsPage {...props} />
              </Suspense>
            ),
            authority: EnumsValues.Functions.TermsAndConditionsUpdate,
            name: 'Términos y condiciones',
            translation_key: 'pages.termsAndConditions',
            key: PageKey.ManageTermsAndConditions,
            requiresTenantSelected: true,
          },
        ],
      },
      {
        path: '/app/termsAndConditions',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <TermsAndConditionsPage mode="registered" />
          </Suspense>
        ),
        name: 'Términos y condiciones',
        translation_key: 'pages.termsAndConditions',
        icon: <FileTextOutlined />,
        key: PageKey.TermsAndConditions,
      },
      {
        path: '/app/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <AppSettingPage />
          </Suspense>
        ),
        name: 'Configuraciones de sistema',
        translation_key: 'pages.configuration',
        icon: <SettingOutlined />,
        key: PageKey.Setting,
      },
      {
        path: '/app/about',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <AboutPage />
          </Suspense>
        ),
        name: 'About',
        translation_key: 'pages.about',
        icon: <BulbOutlined />,
        key: PageKey.About,
      },
      {
        path: '/app/selectTenant',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <SelectTenantPage />
          </Suspense>
        ),
        hideInMenu: true,
        key: PageKey.SelectTenant,
      },
      {
        path: '/app/profile',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ProfilePage />
          </Suspense>
        ),
        name: 'Mi perfil',
        translation_key: 'pages.myProfile',
        hideInMenu: true,
        key: PageKey.Profile,
      },
      {
        path: '/app/checkCompany',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <CheckCompany />
          </Suspense>
        ),
        name: 'Lector QR',
        translation_key: 'pages.readerQR',
        icon: <CheckCircleOutlined />,
        key: PageKey.CheckCompany,
      },

      {
        path: '/app/contact',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ContactPage />
          </Suspense>
        ),
        name: 'Contacto',
        translation_key: 'pages.contact',
        icon: <MailOutlined />,
        key: PageKey.Contact,
      },
      {
        path: '/app/status',
        authority: EnumsValues.Functions.IsAdmin,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <StatusPage />
          </Suspense>
        ),
        name: 'Status info',
        translation_key: 'pages.statusInfo',
        icon: <InfoCircleOutlined />,
        key: PageKey.Status,
      },
      redirectIfNotExist,
    ],
  },
  {
    path: '/',
    component: (props: any) => (
      <Suspense fallback={<PageLoading />}>
        <PublicLayout {...props} />
      </Suspense>
    ),
    key: PageKey.PublicLayout,
    routes: [
      {
        path: '/resetPassword',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <ResetPasswordContentPage />
          </Suspense>
        ),
        key: PageKey.ResetPassword,
      },
      {
        exact: true,
        path: '/setPassword',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <SetPasswordContentPage />
          </Suspense>
        ),
        key: PageKey.SetPassword,
      },
      {
        exact: true,
        path: '/completeRegister',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <CompleteRegisterPage />
          </Suspense>
        ),
        key: PageKey.CompleteRegister,
      },
      {
        exact: true,
        path: '/termsAndConditions',
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <TermsAndConditionsPage mode="public" />
          </Suspense>
        ),
        key: PageKey.TermsAndConditions,
      },
      {
        path: '/login',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <LoginPage {...props} />
          </Suspense>
        ),
        key: PageKey.Login,
      },
      {
        path: '/register',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <RegisterPage />
          </Suspense>
        ),
        key: PageKey.Register,
      },
      {
        path: '/reminder',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Reminder2FA />
          </Suspense>
        ),
        key: PageKey.Reminder,
      },
      {
        path: '/generate',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <Generate2FA {...props} />
          </Suspense>
        ),
        key: PageKey.Generate,
      },
      {
        path: '/validateTotp',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <Validate2FA />
          </Suspense>
        ),
        key: PageKey.ValidateTotp,
      },
      {
        path: '/invitation',
        exact: true,
        component: (props: any) => (
          <Suspense fallback={<PageLoading />}>
            <InvitationPage {...props} />
          </Suspense>
        ),
        translation_key: 'pages.invitation',
        key: PageKey.Invitation,
      },
      {
        path: '/maintenance',
        exact: true,
        component: () => (
          <Suspense fallback={<PageLoading />}>
            <MaintenancePage />
          </Suspense>
        ),
        name: 'En mantenimiento',
        translation_key: 'pages.inMaintenance',
        hideInMenu: true,
        key: PageKey.Maintenance,
      },
      redirectIfNotExist,
    ],
  },
];

export default routes;
