import { ContextApp } from '../contexts/ContextApp';
import { useContext, useState } from 'react';
import { logoutFirebase } from '../configs/firebase/firebase-config';
import { EnumsValues } from '../enums/EnumsValues';
import { Query } from './graphql/query';
import { useCustomLazyQuery } from '../hooks/apollo/ApolloCustomHooks';
import { IUser } from '../interfaces';

export const UserService = () => {
  const {
    setUser,
    setRoles,
    setFunctions,
    setFeatures,
    setSessionExpired,
    user,
    setUserDataFirebase,
    setPictureProfileUrl,
    setTenantsAssociatedToUser,
  } = useContext(ContextApp);
  
  const [authenticateData, setAuthenticateData] = useState<IUser>();

  const [fetchAuthenticate] = useCustomLazyQuery<{
    authenticate: IUser;
  }>(Query.authenticate);

  const [fetchLogout] = useCustomLazyQuery<{
    logout: any;
  }>(Query.logout);

  const authenticate = async (lastTenantId?: number) => {
    try {
      const { data, error } = await fetchAuthenticate({
        variables: {
          tenant_id: lastTenantId,
        },
      });

      if (error) {
        throw error;
      } else if (data) {
        setAuthenticateData(JSON.parse(JSON.stringify(data.authenticate)));
      }
    } catch (error) {
      return null;
    }
  };

  const logout = async () => {
    // TODO: falta implementar lista negra en servicio
    localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
    localStorage.removeItem(EnumsValues.LocalStorageKeys.LastTenantId);
    localStorage.removeItem(EnumsValues.LocalStorageKeys.TwoFactorValidated);
    localStorage.removeItem(EnumsValues.LocalStorageKeys.UserId);
    localStorage.removeItem(EnumsValues.LocalStorageKeys.googleImage);
    if (user?.uid) {
      logoutFirebase();
    }
    setAuthenticateData(undefined);
    setUser(() => null);
    setRoles(() => []);
    setFunctions(() => []);
    setFeatures(() => []);
    setSessionExpired(false);
    setUserDataFirebase(() => null);
    setPictureProfileUrl(() => null);
    setTenantsAssociatedToUser(() => []);
    const { error } = await fetchLogout();

    if (error) {
      throw error;
    }
  };

  const expireSession = () => {
    try {
      localStorage.removeItem(EnumsValues.LocalStorageKeys.Token);
      setRoles(() => []);
      setFunctions(() => []);
      setFeatures(() => []);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  return {
    authenticateData,
    authenticate,
    logout,
    expireSession,
  };
};
