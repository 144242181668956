export const USERS = `
query users(
  $filter: FilterUserInput
  $orderBy: UserOrderInput
  $searchText: String
  $skip: Int
  $take: Int
  $tenant_id: Int!
) {
  users(
    filter: $filter
    orderBy: $orderBy
    searchText: $searchText
    skip: $skip
    take: $take
    tenant_id: $tenant_id
  ) {
    __typename
    ... on User {
      id
      username
      email
      person {
        id
        user_id
        firstname
        lastname
        profile_picture_id
      }
      active
      theme
      lastAccess
      language_id
      is_system_user
      user_role {
        user_id
        role_id
        role {
          name
          id
        }
      }
      profile {
        id
        phone
        timezone
      }
      two_factor_auth_active
      signature_id
      validated
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const LOGIN = `mutation login($username: String!, $password: String!) {
  login(
    username: $username, password: $password
  ) 
  {
    __typename
    ...on Auth {
      token
      user {
        id
        two_factor_auth_active
        last_tenant_id
      }
    }
    ...on ResponseMessage {
      status
      response
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const LOGIN_FIREBASE = `mutation loginFirebase($uid: String!) {
  loginFirebase(
    uid: $uid
  ) 
  {
    __typename
    ...on Auth {
      token
      user {
        id
        last_tenant_id
      }
    }
    ...on ResponseMessage {
      status
      response
    }
    ...on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const AUTH = `
query authenticate($tenant_id: Int) {
  authenticate(tenant_id: $tenant_id) {
    __typename
    ... on AuthOutput {
      id
      username
      person {
        firstname
        lastname
        profile_picture_id
        profile_picture {
          id
          filename
          mimetype
          encoding
          file
          url
        }
      }
      email
      theme
      roles {
        id
        name
      }
      permissions 
      features
			profile_id
      profile{
        id
        phone
        timezone
      }
      language_id
      uid
      two_factor_auth_active
      signature_id
      validated
      accepted_terms_and_conditions
      last_tenant_id
    }
  ...on ResultError {
    status_code
    message
    message_translation_key
  }
  }
}
`;

export const LOGOUT = `
    query logout{
      logout{
        __typename
        ... on BooleanResult{
          result
        }
        ... on ResultError {
          status_code
          message
          message_translation_key
        }
      }
    }
`;

export const RESET_PASSWORD = `
  query resetPassword($username: String!) {
    __typename
    resetPassword(input: { username: $username }) {
      __typename
      result
    }
  }
`;

export const VERIFY_RECOVERY_PASSWORD_TOKEN = `
query verifyRecoveryPasswordToken($token: String!){
  verifyRecoveryPasswordToken(
    token: $token
  ){
    __typename
    ...on RecoveryResult{
      result
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const USERS_COUNT = `
query userCount{
  userCount{
    __typename
    ... on UserCount{
      count
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const USERS_COUNT_BY_ROLE = `
query userCountByRole(
  $tenant_id: Int!
){
  userCountByRole(
    tenant_id: $tenant_id
  ){
    __typename
    ... on UserCountByRole{
      role{
        id
        name
      }
      count
    }
  }
}
`;

export const USER_HAS_PASSWORD = `
query userHasPassword(
  $tenant_id: Int!
){
  userHasPassword(
    tenant_id: $tenant_id
  ){
    __typename
    ... on UserHasPassword{
      userHasPassword
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const TOKEN_USER_HAS_PASSWORD = `
query tokenUserHasPassword($token: String!){
  tokenUserHasPassword(token: $token){
    __typename
    ... on UserHasPassword{
      userHasPassword
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const GET_PROFILE_PICTURE = `
query getProfilePicture(
  $id:  Int!
){
  getProfilePicture(
    id: $id
  ){
    __typename
    ...on StringResult{
      result
        }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const GET_PROFILE_FILE = `
query getProfileFile(
  $tenant_id: Int!,
  $id: Int!
){
  getProfileFile(
    tenant_id: $tenant_id,
    id: $id,
  )
  {
    __typename
    ...on BaseFile{
      filename
      mimetype
      encoding
      file
        }
    ...on ResultError
    {
      status_code
      message
      message_translation_key
    }
  }
  }
`;
