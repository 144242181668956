export const ROLE = `
query role(
    $filter: FilterRoleInput! 
  ){
  role(
      filter: $filter
  )
  {
      __typename
      ... on Role{
          id
          name
          is_system_role
      }
      ... on ResultError{
          status_code
          message
          message_translation_key
        }
  }
  }
`;

export const ROLES = `
query roles(
    $filter: FilterRoleInput
    $orderBy: RoleOrderInput
    $skip: Int
    $take: Int
    $searchText: String
    $tenant_id: Int!
  ) {
    roles(
      filter: $filter
      orderBy: $orderBy
      skip: $skip
      take: $take
      searchText: $searchText
      tenant_id: $tenant_id
    ) {
      ... on Role {
        id
        name
        is_system_role
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
  
  `;
