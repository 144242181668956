import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.less';

import React, {
  StrictMode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IConfig, IConfigApp, IEnvironment } from './interfaces/environment';
import configJson from './config.json';
import { UserService } from './services/UserService';
import { IRole } from './interfaces/role';
import { PageLoading } from '@ant-design/pro-layout';
import { ConfigProvider, Modal, theme } from 'antd';
import { defaultLanguage } from './i18n/i18n';
import { IUser, IUserFirebase } from './interfaces/user';
import { Language } from './interfaces/language';
import { ContextApp } from './contexts/ContextApp';
import AppContent from './AppContent';
import { useCustomMessage } from './hooks';
import {
  EnumsValues,
  ErrorsStatusCode,
  LocalStorageKeys,
  Themes,
  SettingBooleanString,
} from './enums/EnumsValues';
import { IDBPDatabase } from 'idb';
import { IAppSetting, ITenant } from './interfaces';
import { useServiceWorker } from './hooks/useServiceWorker';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/locale/en_US';
import { TParameter, TReturn } from './i18n/i18n.model';
import { StringMap, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ITermsAndConditions } from './interfaces/TermsAndConditions';
import { IResponseMessage } from './interfaces/ResponseMessage';
import { useCustomLazyQuery } from './hooks/apollo/ApolloCustomHooks';
import { Query } from './services/graphql/query';
import { Tools } from './shared';
import { useSubscription } from '@apollo/client';
import { Subscription } from './services/graphql/subscription';

declare global {
  interface Window {
    firebaseui: any;
  }
}

/* No importar de forma directa, utilizar lazy, ya que estos componentes
activan y desactivan sus propias stylesheets, y si son importados normalmente
podrían desactivar reglas de otras stylesheets */
const LightTheme = React.lazy(() => import('./theme/light/LightTheme'));
const DarkTheme = React.lazy(() => import('./theme/dark/DarkTheme'));

export type T = <S extends TParameter>(
  p: S,
  options?: TOptions<StringMap>,
) => TReturn<S>;

const App: React.FC = () => {
  setupIonicReact({
    platform: {
      /** The default `desktop` function returns false for devices with a touchscreen.
       * This is not always wanted, so this function tests the User Agent instead.
       **/
      desktop: (win) => {
        const isMobile =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            win.navigator.userAgent,
          );
        return !isMobile;
      },
    },
  });
  const [i18nT, i18n] = useTranslation();
  const [configApp] = useState<IConfigApp>(configJson);
  const [user, setUser] = useState<IUser | undefined>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [features, setFeatures] = useState<string[]>([]);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [config, setConfig] = useState<IConfig>();
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [menuBreakpointCollapsed, setMenuBreakpointCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [languages, setLanguages] = useState<Language[]>([]);
  const [themeMode, setThemeMode] = useState<string>(Themes.DEFAULT);
  const [
    isRequiredAcceptTemrsAndConditions,
    setIsRequiredAcceptTemrsAndConditions,
  ] = useState<boolean>(false);
  const { authenticate, authenticateData } = UserService();
  const [userDataFirebase, setUserDataFirebase] = useState<
    IUserFirebase | undefined
  >(undefined);
  const [pictureProfileUrl, setPictureProfileUrl] = useState<string>('');
  const [show2FAReminder, setShow2FAReminder] = useState<boolean>(false);
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const [termsAndConditionsVersionActive, setTermsAndConditionsVersionActive] =
    useState<string>('');
  const [tenantsAssociatedToUser, setTenantsAssociatedToUser] = useState<
    ITenant[]
  >([]);
  const [selectedTenantId, setSelectedTenantId] = useState<number>();
  const [
    userHasAcceptedTermsAndConditions,
    setUserHasAcceptedTermsAndConditions,
  ] = useState<boolean>(false);
  const [fullNameMandatory, setFullNameMandatory] = useState<boolean>();
  const [isAppMultitenant, setIsAppMultitenant] = useState<boolean>(false);

  const t: T = (p, options) => {
    return i18nT(p, { ...options, fallbackLng: 'es' });
  };

  const { showMessageError } = useCustomMessage(t);

  const defaultEnvironmentCode = configJson.default_environment;
  const defaultEnvironment = (
    configJson.environments as {
      [key: string]: IEnvironment;
    }
  )[defaultEnvironmentCode];
  const defaultConfig = configJson.default_config;
  const indexedDatabaseRef = useRef<IDBPDatabase<unknown> | null>(null);
  const dataIsRequiredAcceptTermsAndConditionsFetched = useRef<boolean>(false);
  const {
    workerFinished,
    reloadPage,
    postponeFinishedNotification,
    showWorkerNotification,
  } = useServiceWorker({
    currentRoute,
    disableAutoUpdate:
      import.meta.env.VITE_DISABLE_WORKER_AUTO_UPDATE === 'TRUE',
    disableNotification:
      import.meta.env.VITE_DISABLE_WORKER_NOTIFICATION === 'TRUE',
  });

  const [fetchLanguages] = useCustomLazyQuery<{
    languages: Language[];
  }>(Query.languages);

  const [fetchTitle] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: { key: EnumsValues.SettingNames.PageTitle },
    },
  });

  const [fetchMultitenantSetting] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: { key: EnumsValues.SettingNames.MultiTenant },
    },
  });

  const [fetchAcceptTermsAndconditionsIsRequired] = useCustomLazyQuery<{
    getAppSettingByKey: IAppSetting;
  }>(Query.getAppSettingByKey, {
    variables: {
      input: {
        key: EnumsValues.SettingNames.AcceptTermsAndconditionsIsRequired,
      },
    },
  });

  const [fetchUserHasAcceptedTermsAndConditions] = useCustomLazyQuery<{
    userHasAcceptedTermsAndConditions: IResponseMessage;
  }>(Query.userHasAcceptedTermsAndConditions);

  const [fetchTermsAndConditions] = useCustomLazyQuery<{
    termsAndConditions: ITermsAndConditions;
  }>(Query.termsAndConditions);

  const [fetchTenantsAssociatedWithUser] = useCustomLazyQuery<{
    tenantsAssociatedWithUser: ITenant[];
  }>(Query.tenantsAssociatedWithUser);

  const subscriptionResponse = useSubscription(Subscription.userPlanUpdated, {
    variables: { tenant_id: selectedTenantId },
  });

  useEffect(() => {
    /*
     * Al cargar la aplicación, si no se cuenta con mutaciones pendientes,
     * se eliminan los valores optimistas a reemplazar (optimisticValuesToReplace)
     * para liberar espacio del local storage
     */

    const trackedQueries =
      Tools.getStorage(LocalStorageKeys.TrackedQueries) || [];
    if (trackedQueries.length === 0) {
      Tools.setStorage(LocalStorageKeys.optimisticValuesToReplace, []);
    }
  }, []);

  useEffect(() => {
    if (workerFinished && showWorkerNotification) {
      Modal.confirm({
        content: (
          <>
            <div>Hay una actualización disponible</div>
          </>
        ),
        cancelText: 'No gracias',
        okText: 'Actualizar',
        maskClosable: true,
        onOk: () => reloadPage(),
        onCancel: () => postponeFinishedNotification(),
      });
    }
  }, [workerFinished, showWorkerNotification]);

  const getUserHasAcceptedTermsAndConditions = async () => {
    try {
      const { data, error } = await fetchUserHasAcceptedTermsAndConditions();

      if (error) {
        throw error;
      }

      if (data?.userHasAcceptedTermsAndConditions) {
        setUserHasAcceptedTermsAndConditions(
          data.userHasAcceptedTermsAndConditions.status,
        );
      }
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    if (authenticateData) {
      const {
        email,
        id,
        username,
        person,
        roles,
        is_system_user,
        language_id,
        theme,
        profile_id,
        profile,
        uid,
        two_factor_auth_active,
        signature_id,
        validated,
        accepted_terms_and_conditions,
        permissions,
        last_tenant_id,
      } = authenticateData;

      let user: IUser = {
        email,
        id,
        username,
        password: '',
        person,
        roles,
        is_system_user,
        language_id,
        theme,
        profile_id,
        profile,
        uid,
        two_factor_auth_active,
        signature_id,
        validated,
        accepted_terms_and_conditions,
        last_tenant_id,
      };
      getTenantsAssociatedWithUser(last_tenant_id);
      Tools.setStorage(
        EnumsValues.LocalStorageKeys.LastTenantId,
        String(last_tenant_id),
      );
      setSelectedTenantId(last_tenant_id);
      setUser(user);
      setRoles(roles);
      if (two_factor_auth_active) {
        const validate2FA = Tools.getStorage(
          EnumsValues.LocalStorageKeys.TwoFactorValidated,
        );
        if (!validate2FA) {
          Tools.setStorage(
            EnumsValues.LocalStorageKeys.TwoFactorValidated,
            false,
          );
        }
      }
      if (person?.[0]?.profile_picture?.url != null) {
        setPictureProfileUrl(person?.[0]?.profile_picture?.url);
      }
      setFunctions(permissions ?? []);
      setFeatures(features || []);
    }
  }, [authenticateData]);

  const checkAuth = async (lastTenantId?: number): Promise<any> => {
    setShowLoading(true);
    try {
      await authenticate(lastTenantId);
      await getTermsAndConditions();
      await getUserHasAcceptedTermsAndConditions();
    } catch (error: any) {
      showMessageError({
        context: 'App.checkAuth.1',
        error,
      });
    }
    setShowLoading(false);
  };

  useEffect(() => {
    if (!user || !languages) return;

    const userLanguageCode = languages.find(
      (lang) => lang.id === user.language_id,
    )?.language_code;

    userLanguageCode && i18n.changeLanguage(userLanguageCode);
  }, [user, languages]);

  const getLanguages = async () => {
    try {
      const { data, error } = await fetchLanguages();
      if (error) {
        throw error;
      }
      if (data?.languages) {
        setLanguages(data?.languages);
        if (
          !data?.languages.find((item) => item.language_code === i18n.language)
        ) {
          i18n.changeLanguage(defaultLanguage);
          i18n.loadLanguages(data?.languages.map((item) => item.language_code));
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getTitle = async () => {
    try {
      const { data, error } = await fetchTitle();
      if (error) {
        throw error;
      }

      document.title = data?.getAppSettingByKey?.setting_value
        ? data?.getAppSettingByKey?.setting_value
        : EnumsValues.DefaultValues.Title;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getMultitenantSetting = async () => {
    try {
      const { data, error } = await fetchMultitenantSetting();
      if (error) {
        throw error;
      }
      setIsAppMultitenant(
        data?.getAppSettingByKey?.setting_value === SettingBooleanString.True,
      );
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const getTenantsAssociatedWithUser = async (
    lastTenantId: number | undefined,
  ): Promise<ITenant[]> => {
    try {
      const { data } = await fetchTenantsAssociatedWithUser({
        variables: { tenant_id: lastTenantId },
      });

      setTenantsAssociatedToUser(data?.tenantsAssociatedWithUser ?? []);
      return data?.tenantsAssociatedWithUser ?? [];
    } catch (error: any) {
      //Intentional
      return [];
    }
  };

  useEffect(() => {
    if (user?.theme === Themes.DARK && themeMode !== Themes.DARK) {
      setThemeMode(Themes.DARK);
    } else if (themeMode !== Themes.LIGHT) {
      setThemeMode(Themes.LIGHT);
    }
  }, [user]);

  useEffect(() => {
    if (user?.person?.[0]?.profile_picture?.url != null) {
      setPictureProfileUrl(user.person?.[0]?.profile_picture?.url);
    }
  }, [user]);

  const getFeatures = useCallback(async () => {
    try {
      await authenticate(selectedTenantId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [selectedTenantId]);

  useEffect(() => {
    getFeatures();
  }, [subscriptionResponse.data]);

  const getTermsAndConditions = async () => {
    try {
      await fetchTermsAndConditions().then((res) => {
        const { data, error } = res;

        if (error) {
          throw error;
        }

        if (data?.termsAndConditions) {
          setTermsAndConditionsVersionActive(data.termsAndConditions.data);
        }
      });
    } catch (error: any) {
      if (
        error.networkError.status_code !== ErrorsStatusCode.recordNotFoundCode
      ) {
        showMessageError({
          context: 'ManageTermsAndConditions.getTermsAndConditionsVersions.1',
          error: error.networkError,
        });
      }
    }
  };

  const getIsRequiredAcceptTermsAndConditionsSetting = async () => {
    setLoading(true);
    try {
      const { data, error } = await fetchAcceptTermsAndconditionsIsRequired();
      if (error) {
        throw error;
      }
      setIsRequiredAcceptTemrsAndConditions(
        data?.getAppSettingByKey?.setting_value === 't',
      );
    } catch (error) {
      //intentional
    }
    setLoading(false);
  };

  useEffect(() => {
    if (dataIsRequiredAcceptTermsAndConditionsFetched.current) return;
    dataIsRequiredAcceptTermsAndConditionsFetched.current = true;
    getIsRequiredAcceptTermsAndConditionsSetting();
  }, []);

  useEffect(() => {
    getTermsAndConditions();
    getLanguages();
    getTitle();
    getMultitenantSetting();
    const token = Tools.getStorage(EnumsValues.LocalStorageKeys.Token);
    const lastTenantId = Tools.getStorage(
      EnumsValues.LocalStorageKeys.LastTenantId,
    );
    if (!user && token) {
      checkAuth(lastTenantId ? Number(lastTenantId) : undefined);
    } else {
      setShowLoading(() => false);
    }
  }, []);

  const currentLanguage = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return enUS;
      case 'es':
        return esES;
      default:
        return esES;
    }
  }, [i18n.language]);

  return (
    <ContextApp.Provider
      value={{
        configApp,
        user,
        setUser,
        setLoading,
        loading,
        loadingMessage,
        setLoadingMessage,
        setShowLoading,
        showLoading,
        environment: environment || defaultEnvironment,
        setEnvironment,
        roles,
        setRoles,
        functions,
        setFunctions,
        features,
        setFeatures,
        config: config || defaultConfig,
        setConfig,
        dataLoaded,
        setDataLoaded,
        checkAuth,
        mainMenuCollapsed,
        setMainMenuCollapsed,
        menuBreakpointCollapsed,
        setMenuBreakpointCollapsed,
        sessionExpired,
        setSessionExpired,
        languages,
        userDataFirebase,
        setUserDataFirebase,
        pictureProfileUrl,
        setPictureProfileUrl,
        show2FAReminder,
        setShow2FAReminder,
        indexedDatabaseRef,
        maintenanceMode,
        setMaintenanceMode,
        isRequiredAcceptTemrsAndConditions,
        setIsRequiredAcceptTemrsAndConditions,
        t,
        termsAndConditionsVersionActive,
        setTermsAndConditionsVersionActive,
        userHasAcceptedTermsAndConditions,
        setUserHasAcceptedTermsAndConditions,
        getTermsAndConditions,
        fullNameMandatory,
        setFullNameMandatory,
        tenantsAssociatedToUser,
        setTenantsAssociatedToUser,
        getTenantsAssociatedWithUser,
        selectedTenantId,
        setSelectedTenantId,
        isAppMultitenant,
      }}
    >
      <IonApp>
        <ConfigProvider
          locale={currentLanguage}
          theme={{
            algorithm:
              themeMode === Themes.DARK ? theme.darkAlgorithm : undefined,
            token: {
              colorPrimary: '#454f58',
            },
          }}
        >
          {(themeMode === Themes.DARK && <DarkTheme />) || <LightTheme />}
          {!showLoading ? (
            // @ts-ignore
            <IonReactRouter>
              <IonRouterOutlet>
                <StrictMode>
                  <AppContent
                    onRouteChange={(value) => setCurrentRoute(value)}
                  />
                </StrictMode>
              </IonRouterOutlet>
            </IonReactRouter>
          ) : (
            <PageLoading />
          )}
        </ConfigProvider>
      </IonApp>
    </ContextApp.Provider>
  );
};

export default App;
