export const TENANTS = `
  query tenants(
    $tenant_id: Int!,
    $filter: FilterTenantInput,
    $orderBy:  TenantOrderInput,
    $skip: Int,
    $take: Int,
    $searchText: String,
  ) {
    tenants (
      tenant_id: $tenant_id,
      filter: $filter,
      orderBy: $orderBy
      skip: $skip
      take: $take
      searchText: $searchText
    ) {
      __typename
      ... on Tenant {
        id
        name
        slug
        active
        tenant_picture_id
        tenant_picture {
          url
        }
        is_system_tenant
      }
      ... on ResultError {
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const TENANT = `
query tenant($tenant_id: Int!,$input: GetTenantInput!) {
  tenant(input: $input, tenant_id: $tenant_id) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
      tenant_picture_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const TENANT_COUNT = `
query tenantCount($tenant_id: Int!,$filter: FilterTenantInput, $orderBy: TenantOrderInput) {
  tenantCount(tenant_id: $tenant_id, filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const GET_TENANT_PICTURE = `
query getTenantPicture($tenant_id: Int!, $id: Int!){
  getTenantPicture(tenant_id: $tenant_id, id:$id){
    __typename
    ... on StringResult{
      result
    }
    ... on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const TENANTS_ASSOCIATED_WITH_USER = `
query tenantsAssociatedWithUser($tenant_id: Int!) {
  tenantsAssociatedWithUser(tenant_id: $tenant_id) {
    __typename
    ... on Tenant {
      id
      its
      uts
      name
      slug
      active
      tenant_picture_id
      tenant_picture {
        id
        its
        uts
        filename
        mimetype
        encoding
        file
        url
      }
      person {
        id
        tenant_id
        user_id
        active
        profile_picture_id
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
