import { GoogleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';

interface Props {
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
  title: string;
}

export const GoogleButton: FC<Props> = ({ onClick, title }) => {
  return (
    <Button
      type="default"
      onClick={onClick}
      className="googleButton"
      icon={<GoogleOutlined />}
    >
      {title}
    </Button>
  );
};
