import { NavContext } from '@ionic/react';
import { Button } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleButton } from '../../components/auth/GoogleButton/GoogleButton';
import {
  firebaseApp,
  signInWithGoogle,
} from '../../configs/firebase/firebase-config';
import { ContextApp } from '../../contexts/ContextApp';
import GraphqlService from '../../services/graphql/GraphqlService';
import './invitation.less';
import { useCustomMessage } from '../../hooks/useCustomMessage';
import { IInvitation } from '../../interfaces/Invitation';
import {
  useCustomLazyQuery,
  useCustomMutation,
} from '../../hooks/apollo/ApolloCustomHooks';

const InvitationPage = () => {
  const { user, t } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const location = useLocation();
  const [invitationData, setInvitationData] = useState<IInvitation>();
  const [acceptedInvitation, setAcceptedInvitation] = useState<
    boolean | undefined
  >(undefined);
  const [invitationUID, setInvitationUID] = useState<string | null>();
  const { Query, Mutation } = GraphqlService();
  const history = useHistory();
  const [errorResponse, setErrorResponse] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const { messageError, getErrorMessage, messageUpdateSuccess } =
    useCustomMessage();
  const onClickLogin = async () => {
    await signInWithGoogle();
    const unsubscribe = firebaseApp.auth().onAuthStateChanged((user: any) => {
      if (user) {
        handleResponseInvitation(acceptedInvitation, {
          input: {
            uid: user.uid,
            token: user.multiFactor.user.accessToken,
            displayName:
              user.displayName || user.providerData[0].displayName || undefined,
            email: user.email || user.providerData[0].email || undefined,
            photoURL:
              user.photoURL || user.providerData[0].photoURL || undefined,
            phoneNumber:
              user.phoneNumber || user.providerData[0].phoneNumber || undefined,
            providerId: user.providerData[0].providerId,
          },
        });
      }
    });
    unsubscribe();
  };

  const [fetchCheckInvitation] = useCustomLazyQuery<{
    checkInvitation: IInvitation;
  }>(Query.checkInvitation, {
    variables: {
      invitation_uid: invitationUID,
    },
  });

  const getInvitationData = async () => {
    try {
      const data = await fetchCheckInvitation().then((res) => {
        const { data, error } = res;

        if (error) {
          throw error;
        }

        return data;
      });
      setInvitationData(() => data?.checkInvitation);
    } catch (error: any) {
      setErrorResponse(true);
      setResponseMessage(error.message);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitation_uid = params.get('invitation_id');
    setInvitationUID(invitation_uid);
  }, [location.search]);

  useEffect(() => {
    if (invitationUID) {
      getInvitationData();
    }
  }, [invitationUID]);

  const [invitationResponseMutation] = useCustomMutation<{
    invitationResponse: any;
  }>(Mutation.invitationResponse);

  const handleResponseInvitation = useCallback(
    async (accept: any, userInput?: any) => {
      try {
        if (invitationData)
          await invitationResponseMutation({
            variables: {
              invitation_uid: invitationData.uid,
              accept,
              ...userInput,
            },
          });

        messageUpdateSuccess({
          context: 'Invitation.handleResponseInvitation.1',
        });
        history.replace({
          search: '',
        });
        navigate('/');
      } catch (error) {
        messageError({
          context: 'Invitation.handleResponseInvitation.2',
          message: getErrorMessage(error),
        });
      }
    },
    [invitationData],
  );

  useEffect(() => {
    if (acceptedInvitation !== undefined && user) {
      const userInput = { uid: user.uid };
      handleResponseInvitation(acceptedInvitation, userInput);
    } else if (acceptedInvitation === false) {
      handleResponseInvitation(acceptedInvitation);
    }
  }, [acceptedInvitation]);

  return (
    <>
      <div className="invitation-page">
        {invitationData ? (
          <div className="invitation-page__container">
            {acceptedInvitation === undefined && (
              <div className="accept-invitation">
                <h3>
                  {t('abm.acceptInvitationMessage', {
                    replace: {
                      role: invitationData.role?.name,
                      tenant: invitationData.tenant?.name,
                    },
                  })}
                </h3>
                <div className="accept-invitation__buttons">
                  <Button
                    onClick={() => setAcceptedInvitation(false)}
                    type="default"
                  >
                    {t('abm.reject')}
                  </Button>
                  <Button
                    onClick={() => setAcceptedInvitation(true)}
                    type="primary"
                  >
                    {t('abm.accept')}
                  </Button>
                </div>
              </div>
            )}
            {acceptedInvitation !== undefined && !user && (
              <GoogleButton
                onClick={onClickLogin}
                title={t('abm.loginGoogle')}
              />
            )}
          </div>
        ) : (
          errorResponse && (
            <div id="invitation-page-response-message">
              <p>{responseMessage}</p>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default InvitationPage;
