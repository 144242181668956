export const TWO_FACTOR_AUTH = `
mutation TwoFactorAuth($id: Int!, $token: String!) {
  twoFactorAuth(id: $id, token: $token) {
    __typename
    ... on ResponseMessageWithLastTenantId {
      status
      response
      lastTenantId
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const GENERATE_TOTP_URL = `
mutation generateTotpUrl($tenant_id: Int!) {
  generateTotpUrl(tenant_id: $tenant_id) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DISABLE_TWO_FACTOR_AUTH = `
mutation disableTwoFactorAuth($tenant_id: Int!) {
  disableTwoFactorAuth(tenant_id: $tenant_id) {
    __typename
    ... on ResponseMessage {
      status
      response
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
