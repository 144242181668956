import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPDATE_AUTH_DATA,
  COMPLETE_REGISTER,
  RESEND_VALIDATE_TOKEN,
  CREATE_USER_PUBLIC,
  UPDATE_LAST_TENANT_ID_SELECTED,
} from './User';
import { CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from './Company';
import { UPDATE_SETTING, UPDATE_SETTING_TRANSLATION } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import {
  CREATE_OPERATION,
  DELETE_OPERATION,
  UPDATE_IMPORTANT_OPERATION,
  UPDATE_OPERATION,
} from './Operation';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  DISABLE_TWO_FACTOR_AUTH,
  GENERATE_TOTP_URL,
  TWO_FACTOR_AUTH,
} from './Totp2FA';
import { CREATE_SIGNATURE, DELETE_SIGNATURE } from './Signature';
import { CREATE_TENANT, DELETE_TENANT, UPDATE_TENANT } from './Tenant';
import { CREATE_TAG } from './Tag';
import {
  ACTIVE_TERMS_AND_CONDITIONS,
  CREATE_TERMS_AND_CONDITIONS,
  ACCEPT_TERMS_AND_CONDITIONS,
} from './TermsAndConditions';
import { CREATE_LOGIN_METHOD, DELETE_LOGIN_METHOD } from './LoginMethod';
import {
  CREATE_INVITATION,
  INVITATION_RESPONSE,
  DELETE_INVITATION,
  RESEND_INVITATION,
} from './Invitation';
import { CREATE_TENANT_SETTING, UPDATE_TENANT_SETTING } from './TenantSetting';
import { CREATE_NOTE, DELETE_NOTE, UPDATE_NOTE } from './Note';
import { CANCEL_USER_PLAN, UPSERT_USER_PLAN, UPSERT_AUTHORIZED_USER_PLAN } from './UserPlan';

const Mutation = {
  createCompany: { name: 'createCompany', gql: CREATE_COMPANY },
  createOperation: { name: 'createOperation', gql: CREATE_OPERATION },
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteCompany: { name: 'deleteCompany', gql: DELETE_COMPANY },
  deleteOperation: { name: 'deleteOperation', gql: DELETE_OPERATION },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updateAppSettingTranslation: {
    name: 'updateAppSettingTranslation',
    gql: UPDATE_SETTING_TRANSLATION,
  },
  updateCompany: { name: 'updateCompany', gql: UPDATE_COMPANY },
  updateOperation: { name: 'updateOperation', gql: UPDATE_OPERATION },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  createNote: { name: 'createNote', gql: CREATE_NOTE },
  updateNote: { name: 'updateNote', gql: UPDATE_NOTE },
  deleteNote: { name: 'deleteNote', gql: DELETE_NOTE },
  upsertUserPlan: { name: 'upsertUserPlan', gql: UPSERT_USER_PLAN },
  upsertAuthorizedUserPlan: { name: 'upsertUserPlan', gql: UPSERT_AUTHORIZED_USER_PLAN },
  cancelUserPlan: { name: 'cancelUserPlan', gql: CANCEL_USER_PLAN },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  deleteProfilePicture: {
    name: 'deleteProfilePicture',
    gql: DELETE_PROFILE_PICTURE,
  },
  updateAuthData: {
    name: 'updateAuthData',
    gql: UPDATE_AUTH_DATA,
  },
  disableTwoFactorAuth: {
    name: 'disableTwoFactorAuth',
    gql: DISABLE_TWO_FACTOR_AUTH,
  },
  generateTotpUrl: {
    name: 'generateTotpUrl',
    gql: GENERATE_TOTP_URL,
  },
  twoFactorAuth: {
    name: 'twoFactorAuth',
    gql: TWO_FACTOR_AUTH,
  },
  createSignature: {
    name: 'createSignature',
    gql: CREATE_SIGNATURE,
  },
  deleteSignature: {
    name: 'deleteSignature',
    gql: DELETE_SIGNATURE,
  },
  createTenant: {
    name: 'createTenant',
    gql: CREATE_TENANT,
  },
  deleteTenants: {
    name: 'deleteTenants',
    gql: DELETE_TENANT,
  },
  updateTenant: {
    name: 'updateTenant',
    gql: UPDATE_TENANT,
  },
  completeRegister: {
    name: 'completeRegister',
    gql: COMPLETE_REGISTER,
  },
  resendValidateToken: {
    name: 'resendValidateToken',
    gql: RESEND_VALIDATE_TOKEN,
  },
  createUserPublic: {
    name: 'createUserPublic',
    gql: CREATE_USER_PUBLIC,
  },
  createTag: {
    name: 'createTag',
    gql: CREATE_TAG,
  },
  createLoginMethod: {
    name: 'createLoginMethod',
    gql: CREATE_LOGIN_METHOD,
  },
  deleteLoginMethod: {
    name: 'deleteLoginMethod',
    gql: DELETE_LOGIN_METHOD,
  },
  acceptTermsAndConditions: {
    name: 'acceptTermsAndConditions',
    gql: ACCEPT_TERMS_AND_CONDITIONS,
  },
  createTermsAndConditions: {
    name: 'createTermsAndConditions',
    gql: CREATE_TERMS_AND_CONDITIONS,
  },
  activateTermsAndConditions: {
    name: 'activateTermsAndConditions',
    gql: ACTIVE_TERMS_AND_CONDITIONS,
  },
  updateImportantOperation: {
    name: 'updateImportantOperation',
    gql: UPDATE_IMPORTANT_OPERATION,
  },
  createInvitation: {
    name: 'createInvitation',
    gql: CREATE_INVITATION,
  },
  invitationResponse: {
    name: 'invitationResponse',
    gql: INVITATION_RESPONSE,
  },
  deleteInvitation: {
    name: 'deleteInvitation',
    gql: DELETE_INVITATION,
  },
  resendInvitation: {
    name: 'resendInvitation',
    gql: RESEND_INVITATION,
  },
  updateLastTenantIdSelected: {
    name: 'updateLastTenantIdSelected',
    gql: UPDATE_LAST_TENANT_ID_SELECTED,
  },
  updateTenantSettingValue: {
    name: 'updateTenantSettingValue',
    gql: UPDATE_TENANT_SETTING,
  },
  createTenantSettingValue: {
    name: 'createTenantSettingValue',
    gql: CREATE_TENANT_SETTING,
  },
};

export { Mutation };
